<template>
	<div style="height: 100vh;background-color: #F2F3F5;background-image: url('/static/images/home2bg.png')">
		<div style="min-height: 1px"></div>

		<div class="slider_2" style="background-image: url('/static/images/home2bg.png')">
			<div class="slider_2_shapes">
				<!--				<img class="h2_shape4" src="/static/picture/home_2_shape4.png" alt=""/>-->
				<img class="h2_sh_1 d-none d-sm-block" src="/static/picture/bordershape.png" alt="">
				<img class="h2_sh_2 d-none d-sm-block" src="/static/picture/home2shape2.png" alt="">
				<img class="h2_sh_3 d-none d-sm-block" src="/static/picture/h2shap3.png" alt="">
				<img class="h2_sh_4 d-none d-sm-block" src="/static/picture/h2shape2.png" alt="">
				<img class="h2_sh_5 d-none d-sm-block" src="/static/picture/home_2_shape.png" alt="">
				<img class="h2_sh_6 d-none d-sm-block" src="/static/picture/home_2_shape7.png" alt="">
				<img class="h2_sh_7 d-none d-sm-block" src="/static/picture/border2shape.png" alt="">
			</div>
			<div class="container">
				<!--订单状态-->
				<div class="master-div">
					<div style="text-align: center;height: 600px;display:flex;align-items:center;">
						<el-row class="content_div">
							<!--支付成功-->
							<template v-if="payStatus===1">
								<el-col :span="8">
									<div style="text-align: right">
										<el-image src="/static/images/webPay/havePayIcon.png" style="width: 120px;"/>
									</div>
								</el-col>
								<el-col :span="16">
									<div style="text-align: left;font-size: 40px;padding-left: 20px">
									<span>
										订单已支付
									</span>
									</div>
									<div style="text-align: left;font-size: 20px;padding-left: 20px">
										您的订单已支付，请返回微行小程序或APP查看
									</div>
								</el-col>
							</template>
							<template v-if="payStatus!==1">
								<el-col :span="8">
									<div style="text-align: right">
										<el-image src="/static/images/webPay/cancelIcon.png" style="width: 120px;"/>
									</div>
								</el-col>
								<el-col :span="16">
									<div style="text-align: left;font-size: 40px;padding-left: 20px">
									<span>
											支付已取消
									</span>
									</div>
									<div style="text-align: left;font-size: 20px;padding-left: 20px">
										您的订单已取消支付！
									</div>
								</el-col>
							</template>
						</el-row>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {GetApi} from '@/api'

	export default {
		name: "index",
		data() {
			return {
				code: '',

				order: {},

				showStatus: false,
				showCancel: false,
				showHavePay: false,

				payStatus: '',
			}
		},
		created() {
			let query = this.$route.query;
			if (!query || !query.order_id) {
				console.log("无法获取order_id数据");
				this.$router.push({path: '/home'});
				// this.payStatus = 0;
				return
			}
			this.orderId = query.order_id;
			// 查询订单信息
			this.getOrderPayResult()
		},
		methods: {

			getOrderPayResult() {

				let payload = {
					orderId: this.orderId
				};
				GetApi.getOrderResult(payload).then((aData) => {
					this.payStatus = aData.data.status;
				}).catch((err) => {
					console.log(err);
				})
			}
		}
	}
</script>

<style scoped>

	.label_text {
		color: #999;
		font-size: 20px;
	}

	.content_text {
		color: #333333;
		font-size: 20px;
		font-weight: 600;
	}

	.next_page_btn {
		height: 60px;
		width: 300px;
		background-color: #FF453A;
		margin: 0 auto;
		text-align: center;
		line-height: 60px;
		cursor: pointer
	}

	.master-div {
		max-width: 1200px;
		background-color: white;
		margin: 100px auto;
		padding-bottom: 200px;
	}

	.content_div {
		height: 268px;
		display: flex;
		align-items: center;
		border: 1px solid rgba(253, 66, 32, 0.2);
		border-radius: 25px;
		width: 890px;
		margin: 0 auto;

	}
</style>